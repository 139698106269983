import React, { useEffect } from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
//import { StaticImage } from "gatsby-plugin-image";

const ThankYou = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Thank You"
				description="Thank you for your submission, we will be in touch soon!"
			/>
			{/* <div className="">
				<StaticImage
					className="rounded-lg shadow-md transform"
					src="../../images/sikkim-bike-tour-22.jpeg"
					alt="cycling adventures"
					layout="FULL_WIDTH"
					aspectRatio="2.12"
				/>
			</div> */}
			<section className="my-20">
				<h1 className="font-bold">Thank You</h1>
				<h2 className="font-light text-primary max-w-3xl mb-14">
					Many thanks for your request. We will be in touch soon!
				</h2>
				<p className="max-w-2xl">
					We invite you to follow our{" "}
					<a
						href="https://www.instagram.com/artofbicycleglobal/"
						target="blank"
						className="underline font-semibold"
					>
						Instagram Channel
					</a>{" "}
					for the latest updates and stay in the loop.
				</p>
				<button className="mt-6 mb-4">
					<a
						href="https://www.instagram.com/artofbicycleglobal/"
						target="blank"
						className="bg-articlecta text-white font-medium py-2 px-4 rounded-lg"
					>
						Follow us
					</a>
				</button>
			</section>
		</Layout>
	);
};

export default ThankYou;
